#recruitment-connect-container {
  #internal-container {
    width: 100%;
    height: 100%;
    display: flex;
    #app-sidebar {
      z-index: 3;
      -webkit-transition: all linear 0.5s;
      -moz-transition: all linear 0.5s;
      -o-transition: all linear 0.5s;
      transition: all linear 0.5s;
    }
    #app-content {
      width: 100%;
      padding-left: calc(24 * var(--unit));
      -webkit-transition: all linear 0.5s;
      -moz-transition: all linear 0.5s;
      -o-transition: all linear 0.5s;
      transition: all linear 0.5s;
      #header {
        width: 100%;
      }
      #main {
        width: 100%;
        display: flex;
        #main-drawer {
          height: calc(100vh - calc(10 * var(--unit)));
          z-index: 0;
          .drawer-body {
            overflow-y: auto;
          }
          .show-drawer {
            position: absolute;
            z-index: 2;
            margin: calc(2 * var(--unit));
            right: 0;
          }
          &.mobile-drawer {
            position: absolute;
            width: calc(100vw - calc(10 * var(--unit)));
            .drawer-wrapper {
              width: 100%;
            }
          }
        }
        #main-content {
          width: 100%;
          height: calc(100vh - calc(10 * var(--unit)));
          padding: calc(4 * var(--unit));
          overflow-y: auto;
          #breadcrumb {
            padding-bottom: calc(4 * var(--unit));
          }
          .general-page-container {
            width: 100%;
            max-width: 1700px;
          }
          &.old-ui {
            padding: 0 !important;
            #breadcrumb {
              padding-bottom: calc(2 * var(--unit));
              padding-top: calc(2 * var(--unit));
              padding-left: calc(2 * var(--unit));
            }
          }
        }
      }
      &.no-sidebar {
        padding-left: 0 !important;
      }
    }
  }
}

#recruitment-connect-container {
  #public-container,
  #landing-container {
    width: 100%;
    height: 100vh;
    .recruitment-connect-main-view {
      width: 100%;
      height: calc(100vh - var(--footer-height));
      overflow: auto;
    }

    #main {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: $tablet) {
    #public-container {
      width: auto;
      height: 100vh;

      .recruitment-connect-main-view {
        overflow: visible;
        height: auto;
        min-height: auto;

        > div.h-100 {
          height: auto !important;
          min-height: calc(100vh - var(--footer-height));
        }
        > div.overflow-auto {
          overflow: visible !important;
        }
        #knockout-questions .-questions {
          max-height: initial;
          padding-bottom: 10px !important;
        }
        .-arrow-top {
          display: none !important;
        }
        .-arrow-bottom {
          display: none !important;
        }
      }
      #rejected-candidate {
        width: 100%;
      }
    }
  }
}

.small-icon {
  font-size: 15px;
}

.material-icons {
  &.outlined {
    font-family: "Material Icons Outlined";
  }
}

.toggle-handle {
  z-index: 0 !important;
}
